<template>
	<div>
		<div variant="dark" class="bg-dark pt-3 pb-3">
			<b-container>
				<b-row>
					<b-col cols="auto" class="align-self-center">
						<router-link to="/admin/dashboard" class="h4 mr-4">Admin</router-link>
					</b-col>
					<b-col class="align-self-center">
						<b-nav>
							<b-nav-item to="/admin/dashboard">Dashboard</b-nav-item>
							<b-nav-item to="/admin/users">Users</b-nav-item>
							<b-nav-item to="/admin/notifications">Notifications</b-nav-item>
							<b-nav-item to="/admin/offers">Offers</b-nav-item>
							<b-nav-item to="/admin/coupons">Coupons</b-nav-item>
						</b-nav>
					</b-col>
					<b-col cols="auto" class="align-self-center">
						<b-nav class="ml-auto">
							<b-nav-item class="text-muted" to="/">Return to App</b-nav-item>
						</b-nav>
					</b-col>
				</b-row>
			</b-container>
		</div>
		<b-container>
			<transition name="slide-fade">
				<router-view></router-view>
			</transition>
		</b-container>
	</div>
</template>

<script>
	export default {
		name: 'Admin',
	}
</script>

<style lang="scss">
	.admin-header {
		background: #242424;
	}
</style>
