<template>
	<div class="pt-5">
		<h1>Dashboard</h1>
		<b-row>
			<b-col>
				<div class="h3">
					Users
				</div>
				<div class="display-1">
					1,500
				</div>
			</b-col>
			<b-col>
				<div class="h3">
					Total Stylesheets
				</div>
				<div class="display-1">
					4,250
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
export default {
	name: 'AdminDashboard',
}
</script>

<style lang="scss">

</style>
