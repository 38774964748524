<template>
	<div class="pt-5">
		<h1>Offers</h1>
		<b-form @submit.prevent="createOffer" ref="offerForm">
			<b-form-group  label="Message Title" label-for="">
				<b-form-input  required placeholder="Title" v-model="newOffer.title"></b-form-input>
			</b-form-group>

			<b-form-group  label="Message Body" label-for="">
				<b-form-input  required placeholder="Body" v-model="newOffer.body"></b-form-input>
			</b-form-group>

			<b-form-group  label="CTA Link" label-for="">
				<b-form-input  placeholder="Link" v-model="newOffer.link"></b-form-input>
			</b-form-group>

			<b-form-group  label="CTA Text" label-for="">
				<b-form-input  placeholder="Link Message" v-model="newOffer.ctaText"></b-form-input>
			</b-form-group>
			
			<b-form-group  label="BG Image" label-for="">
				<input type="file" @change="e => { loadCanvas(e, 'bgCanvas', 'bgImg'); }" /><br />
				<img :src="newOffer.bgImg" style="width: 500px; max-width: 100%;" />
			</b-form-group>
			
			<b-form-group label="Icon Image" label-for="">
				<input type="file" @change="e => { loadCanvas(e, 'iconCanvas', 'iconImg'); }" /><br />
				<img :src="newOffer.iconImg" style="width: 500px; max-width: 100%;" />
			</b-form-group>
			
			<b-form-group >
				<b-form-checkbox value="true" v-model="newOffer.isActive" switch>Active</b-form-checkbox>
			</b-form-group>

			<b-button type="reset" variant="danger">Reset</b-button>
			<b-button type="submit" variant="primary">Publish</b-button>
		</b-form>
		<div class="pt-5">
			<h3>Published Offers</h3>
			<b-list-group class="offers-list" v-if="offers">
				<draggable v-model="offers" group="offers" @start="drag=true" @end="endDrag">
					<b-list-group-item class="p-4" v-for="offer in offers" v-bind:key="offer._id">
						<b-row>
							<b-col>
								<div class="title h3 mb-3">{{ offer.title }}</div>
								<div class="description">{{ offer.body }}</div>
								<div class="external-link mt-3" v-if="offer.link.length"><a :href="offer.link">{{ offer.link }}</a></div>
							</b-col>
							<b-col cols="auto">
								<div>Published: {{ offer.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</div>
							</b-col>
						</b-row>
						<b-row>
							<b-button variant="primary" @click.prevent="editOffer(offer)">Edit</b-button> <b-button variant="danger" @click.prevent="deleteOffer(offer)">Delete</b-button>
						</b-row>
					</b-list-group-item>
				</draggable>
			</b-list-group>
		</div>
	</div>
</template>

<script>
import draggable from 'vuedraggable';
export default {
	name: 'AdminOffers',
	data () {
		return {
			newOffer: false,
			offers: false
		}
	},
	components: {
		draggable
	},
	computed: {
		Offers () {
			const { Offers } = this.$FeathersVuex.api;
			return Offers;
		}
	},
	methods: {
		endDrag () {
			for (let i=0; i<this.offers.length; i++) {
				this.offers[i].order = i;
				this.offers[i].save();
			}
		},
		createOffer () {
			this.newOffer.save().then(() => {
				this.newOffer = new this.Offers;
				this.newOffer.order = this.offers.length;
				const bgCanvas = this.$refs.bgCanvas;
				const iconCanvas = this.$refs.iconCanvas;
				iconCanvas.width = iconCanvas.height = bgCanvas.width = bgCanvas.height = 0;
			});
		},
		editOffer (offer) {
			this.newOffer = offer;
		},
		deleteOffer (offer) {
			offer.remove().then(() => {
				this.loadOffers();
			});
		},
		loadOffers () {
			this.Offers.find({
				query: {
					$sort: {
						order: 1
					}
				}
			}).then(offers => {
				this.offers = offers.data;
				this.newOffer.order = this.offers.length;
			});
		},
		loadCanvas (e, ref, prop) {
			const files = e.target.files;
			const reader = new FileReader();
			reader.onload = e => {
				this.newOffer[prop] = e.target.result;
			};
			reader.readAsDataURL(files[0]);
		}
	},
	mounted () {
		this.newOffer = new this.Offers;
		this.loadOffers();
		this.newOffer.order = this.offers.length;
		this.Offers.on('created', this.loadOffers);
		this.Offers.on('updated', this.loadOffers);
		this.Offers.on('patched', this.loadOffers);
		this.Offers.on('removed', this.loadOffers);
	},
	beforeDestroy () {
		this.Offers.off('created', this.loadOffers);
		this.Offers.off('updated', this.loadOffers);
		this.Offers.off('patched', this.loadOffers);
		this.Offers.off('removed', this.loadOffers);
	}
}
</script>

<style lang="scss">

</style>
