<template>
	<div class="pt-5">
		<h1>Notifications</h1>
		<b-form @submit.prevent="createNotification">
			<b-form-group id="" label="Message Title" label-for="">
				<b-form-input id="" required placeholder="Title" v-model="newNotification.title"></b-form-input>
			</b-form-group>

			<b-form-group id="" label="Message Body" label-for="">
				<b-form-input id="" required placeholder="Body" v-model="newNotification.body"></b-form-input>
			</b-form-group>

			<b-form-group id="" label="External Link" label-for="">
				<b-form-input id="" placeholder="Link" v-model="newNotification.link"></b-form-input>
			</b-form-group>

			<b-form-group id="" label="External Link Message" label-for="">
				<b-form-input id="" placeholder="Link Message"></b-form-input>
			</b-form-group>

			<b-form-group label="Image" label-for="">
				<input type="file" @change="e => { loadCanvas(e, 'img'); }" /><br />
				<img :src="newNotification.img" style="width: 500px; max-width: 100%;" />
			</b-form-group>

			<b-form-group id="">
				<b-form-checkbox value="true" v-model="newNotification.isPush" switch>Push Notification</b-form-checkbox>
			</b-form-group>

			<b-button type="reset" variant="danger">Reset</b-button>
			<b-button type="submit" variant="primary">Publish</b-button>
		</b-form>
		<div class="pt-5">
			<h3>Published Notifications</h3>
			<b-list-group class="notifications-list" v-if="notifications">
				<b-list-group-item class="p-4" v-for="notification in notifications" v-bind:key="notification._id">
					<b-row>
						<b-col>
							<div class="title h3 mb-3">{{ notification.title }}</div>
							<div class="description">{{ notification.body }}</div>
							<div class="external-link mt-3" v-if="notification.link.length"><a :href="notification.link">{{ notification.link }}</a></div>
							<div class="external-link mt-3" v-if="notification.img.length"><img :src="notification.img"/></div>
						</b-col>
						<b-col cols="auto">
							<div>Published: {{ notification.createdAt | moment("dddd, MMMM Do YYYY, h:mm:ss a") }}</div>
							<div>Sent Push Notification: {{ notification.isPush }}</div>
							<div>Read By: {{ notification.readBy.length }}</div>
						</b-col>
					</b-row>
				</b-list-group-item>
			</b-list-group>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AdminNotifications',
	data () {
		return {
			newNotification: false,
			notifications: false
		}
	},
	computed: {
		Notifications () {
			const { Notifications } = this.$FeathersVuex.api;
			return Notifications;
		}
	},
	methods: {
		createNotification () {
			this.newNotification.save().then(() => {
				this.newNotification = new this.Notifications;
			});
		},
		loadNotifications () {
			this.Notifications.find({
				query: {
					$sort: {
						createdAt: -1
					}
				}
			}).then(notifications => {
				this.notifications = notifications.data;
			});
		},
		loadCanvas (e, prop) {
			const files = e.target.files;
			const reader = new FileReader();
			reader.onload = e => {
				this.newNotification[prop] = e.target.result;
			};
			reader.readAsDataURL(files[0]);
		}
	},
	mounted () {
		this.newNotification = new this.Notifications;
		this.loadNotifications();
		this.Notifications.on('created', this.loadNotifications);
	},
	beforeDestroy () {
		this.Notifications.off('created', this.loadNotifications);
	}
}
</script>

<style lang="scss">

</style>
