<template>
	<div class="pt-5">
		<h1>Coupons</h1>
		<b-form @submit.prevent="createCoupon" ref="couponForm">
			<b-form-group  label="Code" label-for="">
				<b-form-input  required placeholder="Code" v-model="newCoupon.code"></b-form-input>
			</b-form-group>
			
			<b-form-group  label="Price" label-for="">
				<b-form-input  required placeholder="Price" v-model="newCoupon.price"></b-form-input>
			</b-form-group>
			
			<b-form-group  label="Badge" label-for="">
				<b-form-input  required placeholder="Badge" v-model="newCoupon.badge"></b-form-input>
			</b-form-group>

			<b-button type="reset" variant="danger">Reset</b-button>
			<b-button type="submit" variant="primary">Publish</b-button>
		</b-form>
		<div class="pt-5">
			<h3>Published Coupons</h3>
			<b-table v-if="coupons" class="text-white" striped hover :items="coupons" :fields="['code', 'price', 'badge', { key: 'actions', label: '' }]">
				<template #cell(actions)="row">
					<b-button variant="danger" @click.prevent="deleteCoupon(row.item)" size="sm" class="mr-1">
						Remove
					</b-button>
				</template>
			</b-table>
		</div>
	</div>
</template>

<script>
export default {
	name: 'AdminCoupons',
	data () {
		return {
			newCoupon: false,
			coupons: false
		}
	},
	computed: {
		Coupons () {
			return this.$FeathersVuex.api.Coupons;
		}
	},
	methods: {
		createCoupon () {
			this.newCoupon.save().then(() => {
				this.newCoupon = new this.Coupons;
				this.loadCoupons();
			});
		},
		editCoupon (coupon) {
			this.newCoupon = coupon;
		},
		deleteCoupon (coupon) {
			coupon.remove().then(() => {
				this.loadCoupons();
			});
		},
		loadCoupons () {
			this.Coupons.find().then(coupons => {
				this.coupons = coupons.data;
			});
		}
	},
	mounted () {
		this.newCoupon = new this.Coupons;
		this.loadCoupons();
		this.Coupons.on('created', this.loadCoupons);
		this.Coupons.on('updated', this.loadCoupons);
		this.Coupons.on('patched', this.loadCoupons);
		this.Coupons.on('removed', this.loadCoupons);
	},
	beforeDestroy () {
		this.Coupons.off('created', this.loadCoupons);
		this.Coupons.off('updated', this.loadCoupons);
		this.Coupons.off('patched', this.loadCoupons);
		this.Coupons.off('removed', this.loadCoupons);
	}
}
</script>

<style lang="scss">

</style>
