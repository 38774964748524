<template>
<div class="pt-5">
	<h1>Users</h1>
	<b-tabs content-class="mt-3">
		<b-tab>
			<template v-slot:title>
				All <b-badge>10</b-badge>
			</template>

			<b-form-group>
				<b-input-group>
					<b-form-input v-model="filter" type="search" id="filterInput" placeholder="Type to Search"></b-form-input>
					<b-input-group-append>
						<b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>

			<b-table dark borderless hover :fields="fields" :items="items" :filter="filter" :filterIncludedFields="filterOn" @filtered="onFiltered">
				<template #cell(subscription.status)="data">
					<p v-if="data.item.subscription.status && data.item.subscription.cancel_at < 1">{{ data.item.subscription.status }}</p>
					<p v-else-if="data.item.subscription.cancel_at > 0">Ending on {{ data.item.subscription.cancel_at | moment("MMM, DD YYYY") }}</p>
					<p v-else>Inactive</p>
				</template>
				<template #cell(subscription.planName)="data">
					<p v-if="data.item.subscription.status">{{ data.item.subscription.planName }}</p>
					<p v-else>Free</p>
				</template>
				<template #cell(action)="data">
					<b-row>
						<b-col lg="12"><b-button v-b-modal.user-detail-modal variant="primary" size="sm" @click="userKey = data.index">Details</b-button></b-col>
					</b-row>
				</template>
			</b-table>

			<b-modal id="user-detail-modal">
				<template v-slot:modal-header>
					User Actions
				</template>
				<div>
					<b-list-group v-if="userKey !== false">
						<b-list-group-item class="d-flex align-items-center"><b class="mr-auto">Email:</b> {{ inspectedUser.email }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center"><b class="mr-auto">Roles:</b> {{ inspectedUser.roles }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center"><b class="mr-auto">Signed Up:</b> {{ inspectedUser.createdAt | moment('MMM DD, YYYY @ HH:mma') }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center"><b class="mr-auto">Oauth Verified:</b> {{ inspectedUser.oauthVerified }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center"><b class="mr-auto">Migrated:</b> {{ inspectedUser.migrated }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center"><b class="mr-auto">Pro Status:</b> {{ inspectedUser.subscription.status || 'Inactive' }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center" v-if="inspectedUser.subscription.status"><b class="mr-auto">Plan:</b> {{ inspectedUser.subscription.plan.nickname }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center" v-if="inspectedUser.subscription.status"><b class="mr-auto">Period Start:</b> {{ inspectedUser.subscription.periodStartDate | moment('MMM DD, YYYY') }}</b-list-group-item>
						<b-list-group-item class="d-flex align-items-center" v-if="inspectedUser.subscription.status"><b class="mr-auto">Period End:</b> {{ inspectedUser.subscription.periodEndDate | moment('MMM DD, YYYY') }}</b-list-group-item>
					</b-list-group>
				</div>
				<template v-slot:modal-footer>
					<b-button-group v-if="userKey !== false">
						<b-button :href="stripeCustomer(inspectedUser.customerId)" target="_blank" variant="warning" style="height: 42px" class="mr-4"><img src="https://jotformapps.s3.amazonaws.com/156362680544445564115_Stripe.png" style="height: 20px;" /> Customer</b-button>
						<b-button @click="suspendCustomer(inspectedUser, false)" variant="success" v-if="inspectedUser.isSuspended">Unsuspend</b-button>
						<b-button @click="suspendCustomer(inspectedUser, true)" variant="danger" v-else>Suspend</b-button>
						<!--<b-button>Delete</b-button>-->
					</b-button-group>
				</template>
			</b-modal>
		</b-tab>
		<b-tab lazy>
			<template v-slot:title>
				Free Tier <b-badge>10</b-badge>
			</template>
		</b-tab>
		<b-tab lazy>
			<template v-slot:title>
				Pro Tier <b-badge>10</b-badge>
			</template>
		</b-tab>
	</b-tabs>
</div>
</template>

<script>
export default {
	name: 'AdminUsers',
	data() {
		return {
			fields: [
				{
					key: 'email',
					sortable: true
				},
				{
					key: 'roles',
					sortable: false
				},
				{
					label: 'Pro Status',
					key: 'subscription.status',
					sortable: true
				},
				{
					label: 'Plan',
					key: 'subscription.planName',
					sortable: true,
					default: 'None'
				},
				'action'
			],
			items: [],
			filter: '',
			filterOn: ['email'],
			userKey: false
		}
	},
	methods: {
		onFiltered(filteredItems) {
			this.totalRows = filteredItems.length
			this.currentPage = 1
		},
		findUsers () {
			this.User.find().then(res => {
				this.items = res.data;
			});
		},
		stripeCustomer (id) {
			return 'https://dashboard.stripe.com/' + (process.env.NODE_ENV !== 'production' ? 'test/' : '') + 'customers/' + id;
		},
		suspendCustomer (user, status) {
			user.isSuspended = status;
			user.patch();
		}
	},
	computed: {
		User () {
			const { User } = this.$FeathersVuex.api;
			return User;
		},
		inspectedUser () {
			return new this.User(this.items[this.userKey]);
		}
	},
	mounted () {
		this.findUsers();
	}
}
</script>

<style lang="scss">

</style>
